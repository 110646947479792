<template>
  <div class="next-edit">
    <!-- custom-class="common-dialog" -->
    <el-dialog
      title="批量编辑"
      :visible.sync="drawerVisible"
      :close-on-click-modal="false"
      width="1500px"
      append-to-body
      custom-class="common-dialog"
      @closed="handleClose"
    >
      <div
        class="next-edit-container"
        v-loading="nextEditLoading"
      >
        <div class="next-edit-tree">
          <snap-tree
            ref="snapTreeRef"
            :level="level"
            :treeParams="treeParams"
            @validateData="validateData"
            @setSelectList="setSelectList"
            @handleLoading="handleLoading"
            @assembleParams="assembleParams"
            @onWatchConfirmStatus="onWatchConfirmStatus"
          />
        </div>
        <div class="next-edit-content">
          <campaign
            v-if="currentLevel === 'campaign'"
            ref="campaignRef"
            :key="componentKey"
            :contentData="contentData"
            @setName="setName"
          />
          <ad-group
            v-if="currentLevel === 'adset'"
            ref="adGroupRef"
            :enumConstants="enumConstants"
            :nextEdit="true"
            :key="componentKey"
            :contentData="contentData"
            :partDisabled="partDisabled"
            @setName="setName"
          />
          <ads
            v-if="currentLevel === 'ad'"
            ref="adsRef"
            :enumConstants="enumConstants"
            :nextEdit="true"
            :key="componentKey"
            :contentData="contentData"
            :storageDisabled="storageDisabled"
          />
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          @click="handleSure"
          type="primary"
          :disabled="confirmDisabled"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SnapTree from './SnapTree.vue';
import campaign from '@/views/multiPlatform/components/editSnap/campaign.vue';
import adGroup from '@/views/multiPlatform/components/editSnap/adGroup.vue';
import ads from '@/views/multiPlatform/components/editSnap/ads.vue';
import { getEnumConstants } from '@/api/snapchat/create.js';
import { copyToCreate } from '@/api/snapchat/edit';
import { debounce } from 'lodash';
export default {
  data() {
    return {
      drawerVisible: false,
      originList: [],
      currentLevel: '',
      enumConstants: {},
      contentData: {},
      timer: null, // 定时器
      nextEditLoading: false,
      isValid: true,
      levelRef: {
        campaign: 'campaignRef',
        adset: 'adGroupRef',
        ad: 'adsRef',
      },
      componentKey: 0,
      confirmDisabled: false,
      storageDisabled: false,
      partDisabled: false,
    };
  },
  props: {
    nextEditDialogShow: {
      type: Boolean,
      default: false,
    },
    treeParams: {
      type: Object,
      default: () => ({}),
    },
    level: {
      type: String,
      default: '',
    },
  },
  components: { SnapTree, campaign, adGroup, ads },
  methods: {
    onWatchConfirmStatus(confirmDisabled) {
      this.confirmDisabled = confirmDisabled;
    },
    handleSure: debounce(async function () {
      const isValid = await this.$refs[this.levelRef[this.currentLevel]].checkForm();
      if (!isValid) {
        this.$message.warning('请检查当前页面的必填项是否填写完整');
        return;
      }
      const { selectedId, selectedLevel, treeData } = this.$refs.snapTreeRef.getAssembleParams();
      await this.assembleParams(selectedId, selectedLevel, treeData);
      const copyData = this.$refs.snapTreeRef.getCopyData();
      try {
        this.$showLoading();
        const res = await copyToCreate(copyData);
        if (res.code === 0) {
          this.$message.success('复制成功');
          this.handleClose();
          this.$emit('multipleCopySuccess');
        }
      } catch (error) {
      } finally {
        this.$hideLoading();
      }
    }, 300),
    handleClose() {
      this.$emit('updateNextEditDialogShow');
    },
    validateData: debounce(async function (level, callback) {
      try {
        this.isValid = await this.$refs[this.levelRef[level]].checkForm();

        if (!this.isValid) {
          this.$message.warning('请检查当前页面的必填项是否填写完整');
        }

        if (callback && typeof callback === 'function') {
          // 调用传递过来的回调函数并传入更新的 isValid 值
          callback(this.isValid);
        }
      } catch (error) {
        console.error('Validation error:', error);
      }
    }, 300),
    findOptimizationGoal(data, targetId) {
      for (const item of data) {
        for (const adsetItem of item.adsetList) {
          for (const ad of adsetItem.adList) {
            if (ad.id === targetId) {
              return adsetItem.adGroupOption.optimizationGoal;
            }
          }
        }
      }
      return null; // 如果没有找到匹配项
    },
    findTypesById(data, targetId) {
      const types = [];

      data.forEach((item) => {
        item.adsetList?.forEach((adset) => {
          if (adset.id === targetId) {
            adset.adList?.forEach((ad) => {
              types.push(ad.creative.type);
            });
          }
        });
      });

      return types;
    },
    setSelectList(data, level) {
      this.currentLevel = level;

      if (level == 'campaign') {
        this.contentData = {
          id: data.id,
          name: data.name,
          status: data.status,
          canEdit: data.canEdit,
        };
        this.componentKey += 1; // 增加或改变 key 来强制重新渲染
      }
      if (level === 'adset' || level === 'ad') {
        this.contentData = data;
        this.componentKey += 1; // 增加或改变 key 来强制重新渲染
      }
    },
    handleLoading(val) {
      this.nextEditLoading = val;
    },
    setName(level, id, name) {
      this.$refs.snapTreeRef.setName(level, id, name);
    },
    /** 获取常量信息 */
    getEnumConstants() {
      this.$showLoading();
      getEnumConstants()
        .then((res) => {
          if (res.code == 0) {
            this.enumConstants = res.data;
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    async assembleParams(selectedId, selectedLevel, treeData) {
      if (selectedLevel === 'campaign') {
        await this.$refs.campaignRef.getFormData().then((res) => {
          treeData.forEach((item) => {
            if (item.id === selectedId) {
              item.name = res.name;
              item.status = res.status;
            }
          });
          this.$refs.snapTreeRef.updateTreeData(treeData);
        });
      }
      if (selectedLevel === 'adset') {
        await this.$refs.adGroupRef.getFormData().then((res) => {
          treeData.forEach((item) => {
            if (item?.adsetList.length > 0) {
              item.adsetList.forEach((adset) => {
                if (adset.id === selectedId) {
                  adset.adGroupTargeting = res.adGroupTargeting;
                  adset.adGroupOption = res.adGroupOption;
                  adset.adsetName = res.adsetName;
                }
              });
              item.children = item.adsetList;
            }
          });
          this.$refs.snapTreeRef.updateTreeData(treeData);
        });
      }
      if (selectedLevel === 'ad') {
        await this.$refs.adsRef.getFormData().then((res) => {
          treeData.forEach((item) => {
            if (item?.adsetList.length > 0) {
              item.adsetList.forEach((adset) => {
                if (adset?.adList.length > 0) {
                  adset.adList.forEach((ad) => {
                    if (ad.id === selectedId) {
                      ad.adName = res.adName;
                      ad.creative = res.creative;
                    }
                  });
                  adset.children = adset.adList;
                }
              });
            }
          });
        });
      }
      // 对广告类型和优化目标进行部分禁用处理 -->> 双向禁用
      if (this.currentLevel === 'ad') {
        const newTreeData = this.$refs.snapTreeRef.treeData;
        const optiGoal = this.findOptimizationGoal(newTreeData, this.contentData.id);
        this.storageDisabled = optiGoal === 'PIXEL_ADD_TO_CART' || optiGoal === 'PIXEL_PAGE_VIEW';
      }
      if (this.currentLevel === 'adset') {
        const newTreeData = this.$refs.snapTreeRef.treeData;
        const typeList = this.findTypesById(newTreeData, this.contentData.id)
        this.partDisabled = typeList.includes('COMPOSITE')
      }
    },
  },
  mounted() {
    this.drawerVisible = this.nextEditDialogShow;
    this.getEnumConstants();
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.next-edit-container {
  display: flex;
  .next-edit-tree {
    width: 20%;
    min-height: 520px;
    border-right: 1px solid #ccc;
    padding-right: 10px;
  }
  .next-edit-content {
    width: 80%;
    min-height: 520px;
    max-height: 700px;
    overflow-y: scroll;
    border-right: 1px solid #ccc;
    padding: 0 20px;
  }
}
</style>
