<template>
  <div>
    <el-input
      class="filter-tree-input"
      v-model="filterText"
      clearable
      size="small"
      placeholder="请输入关键字进行过滤"
      @input="handleInputFilter"
    />
    <el-tree
      ref="tree"
      :data="treeData"
      show-checkbox
      default-expand-all
      node-key="id"
      :props="defaultProps"
      :filter-node-method="filterNode"
      :highlight-current="false"
      :check-strictly="true"
      :expand-on-click-node="false"
      @node-click="handleNodeClick"
      @check-change="handleCheckChange"
    >
      <div
        slot-scope="{ node, data }"
        :class="['ellipsis', { 'selected-node': checkedNodes.includes(data.id) }]"
      >
        <el-tooltip
          :content="node.label"
          placement="right"
          :disabled="node.label.length <= 25"
        >
          <span>{{ node.label }}</span>
        </el-tooltip>
      </div>
    </el-tree>
  </div>
</template>

<script>
import { snapAdsEcFetchApi } from '@/api/snapchat/edit';
import adLodash from 'lodash';
export default {
  data() {
    return {
      filterText: '',
      checkedNodes: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      selectedId: null,
      selectedLevel: null,
      selectedNodeKey: null,
    };
  },
  props: {
    treeParams: {
      type: Object,
      default: () => ({}),
    },
    level: {
      type: String,
      default: '',
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true; // 如果没有过滤条件，则显示所有节点
      return data.label.indexOf(value) !== -1; // 根据过滤条件判断是否显示节点
    },
    handleInputFilter() {
      this.$refs.tree.filter(this.filterText); // 当输入框内容变化时调用filter方法1
    },
    handleNodeClick(data, node, nodeComponent) {
      this.commonChange(data);
    },
    handleCheckChange(data, checked) {
      if (checked) {
        // 如果某个节点被选中，则取消其他所有节点的选择状态
        this.commonChange(data);
      }
    },
    commonChange(data) {
      this.$emit('validateData', this.selectedLevel);
      // const isValid = true;
      this.$emit('validateData', this.selectedLevel, (isValid) => {
        if (!isValid) {
          // 如果校验不通过，恢复到之前选中的节点
          if (this.selectedId !== null) {
            this.$refs.tree.setCurrentKey(this.selectedId);
          }
          // alert('节点信息无效，不能切换！');
        } else {
          this.$emit('assembleParams', this.selectedId, this.selectedLevel, this.treeData);
          this.uncheckAllNodes();
          this.$refs.tree.setChecked(data.id, true);
          this.checkedNodes = [data.id];
          this.selectedId = data.id;
          this.selectedLevel = data.level;
          this.$emit('setSelectList', data, data.level, this.treeData);
        }
      });
    },
    getAssembleParams() {
      return {
        selectedId: this.selectedId,
        selectedLevel: this.selectedLevel,
        treeData: this.treeData,
      };
    },
    uncheckAllNodes() {
      // 获取所有节点
      const allNodes = this.$refs.tree.store._getAllNodes();
      // 遍历所有节点，取消选择状态
      allNodes.forEach((node) => {
        this.$refs.tree.setChecked(node.data.id, false);
      });
      this.checkedNodes = [];
    },
    getChildren(node, level) {
      if (level === 1 && node.adsetList) return node.adsetList;
      if (level === 2 && node.adList) return node.adList;
      return node.children || [];
    },
    transformTreeForRender(data, level = 1) {
      return data.map((item) => {
        item.disabled = !item.canEdit;
        // 根据层级和现有字段设置label
        if (level === 1 && item.name) {
          item.label = item.name;
        } else if (level === 2 && item.adsetName) {
          item.label = item.adsetName;
        } else if (level === 3 && item.adName) {
          item.label = item.adName;
        }

        // 设置children属性
        item.children = this.getChildren(item, level);

        // 如果有子节点，递归处理
        if (item.children && item.children.length > 0) {
          this.transformTreeForRender(item.children, level + 1);
        }

        return item;
      });
    },
    async getTreeList() {
      try {
        this.$emit('handleLoading', true);
        const res = await snapAdsEcFetchApi(this.treeParams);
        if (Array.isArray(res.data) && res.data.length > 0) {
          this.treeData = this.transformTreeForRender(res.data);
          let treeContent = {
            id: res.data[0].id,
            name: res.data[0].name,
            status: res.data[0].status,
          };
          if (this.level === 'campaign') {
            this.selectedId = res.data[0].id;
            this.selectedLevel = res.data[0].level;
          } else if (this.level === 'adset') {
            this.selectedId = res.data[0].adsetList[0].id;
            this.selectedLevel = res.data[0].adsetList[0].level;
            treeContent = res.data[0].adsetList[0];
          } else if (this.level === 'ad') {
            this.selectedId = res.data[0].adsetList[0].adList[0].id;
            this.selectedLevel = res.data[0].adsetList[0].adList[0].level;
            treeContent = res.data[0].adsetList[0].adList[0];
          }
          this.$refs.tree.setCheckedKeys([this.selectedId]);
          this.$emit('setSelectList', treeContent, this.level);
          this.$emit('onWatchConfirmStatus', false);
        } else {
          this.$emit('onWatchConfirmStatus', true);
        }
      } finally {
        this.$emit('handleLoading', false);
      }
    },
    getCopyData() {
      let treeData = adLodash.cloneDeep(this.treeData);

      return this.renameChildrenProperties(treeData);
    },
    renameChildrenProperties(tree, depth = 1) {
      if (!Array.isArray(tree)) {
        return [];
      }

      // 使用 map 函数遍历并修改每个节点，将修改后的节点返回到新的数组中
      return tree.map((node) => {
        const newNode = { ...node }; // 创建新对象以避免直接修改原始数据

        if (depth === 1 && node.children) {
          // 在第一层，将 `children` 替换为 `adsetList`
          newNode.adsetList = this.renameChildrenProperties(node.children, depth + 1);
          delete newNode.children;
        } else if (depth === 2 && node.children) {
          // 在第二层，将 `children` 替换为 `adList`
          newNode.adList = this.renameChildrenProperties(node.children, depth + 1);
          delete newNode.children;
        } else if (depth > 2 && node.children) {
          // 如果有第三层或更多层，继续递归处理
          newNode.children = this.renameChildrenProperties(node.children, depth + 1);
        }

        return newNode;
      });
    },
    updateTreeData(newData) {
      this.treeData = newData;
    },
    setName(level, id, name) {
      if (level === 'campaign') {
        this.treeData.forEach((item) => {
          if (item.id === id) {
            item.name = name;
            item.label = name;
          }
        });
      } else if (level === 'adset') {
        this.treeData.forEach((item) => {
          if (item.children) {
            item.children.forEach((item2) => {
              if (item2.id === id) {
                item2.adsetName = name;
                item2.label = name;
              }
            });
          }
        });
      }
    },
  },
  created() {
    this.getTreeList();
  },
};
</script>
<style lang="scss" scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
}
.selected-node {
  background-color: #f0f7ff; /* 或者你喜欢的任何颜色 */
}
.filter-tree-input {
  margin-bottom: 10px;
}
</style>
